@import "csstyle/csstyle";
@import "./reset";
@import "./settings/variables";
@import "./overrides";

//@NOTE: This is for temp stuff only - don't let it grow :)
@import "./inbox";
@import "./_fonts";
@import "./_shares";

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 16px;
}

body {
  padding: 0;
  font-family: "open sans", sans-serif;
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: $clr_text;
}

p {
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: #428bca;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.container-large {
  max-width: 1250px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.main {
  & > * {
    // should usually be handled by a container class (or items-stretch), but we can ensure it for extra safety.
    width: 100%;

    &:last-child {
      // in order to support sticky footer behaviour, we need the last child of the main element
      // to expand to meet the footer at the bottom of the page when there is space (eg: login page)
      // this avoids white gaps and ensures the last component is flush with the footer
      flex: 1 0 auto;
    }
  }
}

.well {
  @include well($clr_lightMidGrey);
}
