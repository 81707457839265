.text-black {
  color: black !important;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.max-w-70 {
  max-width: 100%;

  @include breakpoint(tablet) {
    max-width: 70%;
  }
}

.max-w-75 {
  max-width: 75%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.min-h-screen {
  min-height: 100vh;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-grow {
  flex-grow: 1;
}

.items-stretch {
  align-items: stretch;
}

.w-auto {
  width: auto;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.333333%;
}

.w-66 {
  width: 66.666667%;
}

.w-25 {
  width: 25%;
}

.w-75 {
  width: 75%;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}

.w-16 {
  width: 16.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-svw {
  width: 100svw;
}

.w-lvw {
  width: 100lvw;
}

.w-dvw {
  width: 100dvw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}
